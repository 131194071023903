// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.invoice-table {
    color: white !important;
    background: linear-gradient(to right, #fb794a, #edb038) !important;
    border: 1px solid black !important;
  }
  
  
  /* .invoice-table {
    color: white;
    background:linear-gradient(to right, #fb794a, #edb038);
    border: 1px solid black;
  } */
  
  .invoice-table th {
  
    font-size: 14px;
    text-align: start;
    padding: 10px;
    color: white !important;
    background: linear-gradient(to right, #fb794a, #edb038) !important;
    border: 1px solid black !important;
  }
  
  .invoice-table h5 {
    font-size: inherit;
  }
  .invoice-temp-container {
    width: "40%";
    height: "50%";
  }`, "",{"version":3,"sources":["webpack://./src/styles/Receipt.css"],"names":[],"mappings":";;;AAGA;IACI,uBAAuB;IACvB,kEAAkE;IAClE,kCAAkC;EACpC;;;EAGA;;;;KAIG;;EAEH;;IAEE,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,kEAAkE;IAClE,kCAAkC;EACpC;;EAEA;IACE,kBAAkB;EACpB;EACA;IACE,YAAY;IACZ,aAAa;EACf","sourcesContent":["\n\n\n.invoice-table {\n    color: white !important;\n    background: linear-gradient(to right, #fb794a, #edb038) !important;\n    border: 1px solid black !important;\n  }\n  \n  \n  /* .invoice-table {\n    color: white;\n    background:linear-gradient(to right, #fb794a, #edb038);\n    border: 1px solid black;\n  } */\n  \n  .invoice-table th {\n  \n    font-size: 14px;\n    text-align: start;\n    padding: 10px;\n    color: white !important;\n    background: linear-gradient(to right, #fb794a, #edb038) !important;\n    border: 1px solid black !important;\n  }\n  \n  .invoice-table h5 {\n    font-size: inherit;\n  }\n  .invoice-temp-container {\n    width: \"40%\";\n    height: \"50%\";\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
