// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/fonts/Smoothy/Smoothy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Smoothy';
  src: local('Smoothy'),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

.common-color{
  color: white !important;
  background: linear-gradient(to right, #fb794a, #edb038);
}

.badgeColor{
  color:#edb038;
}
.pdf-button{
  color: white !important;
  background-color: #383838 !important;
}

.baseline{
  display: flex;
  align-items: baseline !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
  display: flex;
  align-items: baseline !important;
}


.brown{
  color: white !important;
  background-color: #383838 !important;
}


.slate{
  background-color:rgba(241, 245, 249, 1);
  display: flex;
  flex-direction: column;
  height: 100vh; 
 
  color: rgba(241, 245, 249, 1);;
 

}


@media print {
  html, body {
     display: none;  /* hide whole page */
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iCAAiC;AACnC;;AAEA;EACE,sBAAsB;EACtB;8DACkE;AACpE;;AAEA;EACE,uBAAuB;EACvB,uDAAuD;AACzD;;AAEA;EACE,aAAa;AACf;AACA;EACE,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC;;;AAGA;EACE,uBAAuB;EACvB,oCAAoC;AACtC;;;AAGA;EACE,uCAAuC;EACvC,aAAa;EACb,sBAAsB;EACtB,aAAa;;EAEb,6BAA6B;;;AAG/B;;;AAGA;EACE;KACG,aAAa,GAAG,oBAAoB;EACvC;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Roboto', sans-serif;\n}\n\n@font-face {\n  font-family: 'Smoothy';\n  src: local('Smoothy'),\n    url(../src/fonts/Smoothy/Smoothy-Regular.ttf) format('truetype');\n}\n\n.common-color{\n  color: white !important;\n  background: linear-gradient(to right, #fb794a, #edb038);\n}\n\n.badgeColor{\n  color:#edb038;\n}\n.pdf-button{\n  color: white !important;\n  background-color: #383838 !important;\n}\n\n.baseline{\n  display: flex;\n  align-items: baseline !important;\n}\n\n.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{\n  display: flex;\n  align-items: baseline !important;\n}\n\n\n.brown{\n  color: white !important;\n  background-color: #383838 !important;\n}\n\n\n.slate{\n  background-color:rgba(241, 245, 249, 1);\n  display: flex;\n  flex-direction: column;\n  height: 100vh; \n \n  color: rgba(241, 245, 249, 1);;\n \n\n}\n\n\n@media print {\n  html, body {\n     display: none;  /* hide whole page */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
