// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectPage {
    margin-top: 56px;
  }
  
  .ProjectPage-title-container {
    background-color: #383838;
    color: #ffffff;
    padding: 3rem 0 8rem 0;
  }
  .ProjectPage-title-container h1 {
    font-size: 2.3rem;
    font-weight: 400;
    width: 70%;
    margin: 0 auto 0.7rem auto;
    text-align: center;
    color: #ffffff;
  }
  .ProjectPage-title-container p {
    font-size: 0.8rem;
    font-weight: 300;
    width: 55%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.2rem;
  }
  
  .events-card-container {
    margin: -6.5rem 0 6rem 0;
  }
  
  @media screen and (max-width: 560px) {
    .ProjectPage-title-container h1 {
      font-size: 30px;
      font-weight: 400;
      width: 90%;
      margin: 0 auto 0.7rem auto;
      text-align: center;
    }
    .ProjectPage-title-container p {
      font-size: 14px;
      font-weight: 300;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      line-height: 1.2rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Projectpage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,sBAAsB;EACxB;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;IACV,0BAA0B;IAC1B,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE;MACE,eAAe;MACf,gBAAgB;MAChB,UAAU;MACV,0BAA0B;MAC1B,kBAAkB;IACpB;IACA;MACE,eAAe;MACf,gBAAgB;MAChB,UAAU;MACV,cAAc;MACd,kBAAkB;MAClB,mBAAmB;IACrB;EACF","sourcesContent":[".ProjectPage {\n    margin-top: 56px;\n  }\n  \n  .ProjectPage-title-container {\n    background-color: #383838;\n    color: #ffffff;\n    padding: 3rem 0 8rem 0;\n  }\n  .ProjectPage-title-container h1 {\n    font-size: 2.3rem;\n    font-weight: 400;\n    width: 70%;\n    margin: 0 auto 0.7rem auto;\n    text-align: center;\n    color: #ffffff;\n  }\n  .ProjectPage-title-container p {\n    font-size: 0.8rem;\n    font-weight: 300;\n    width: 55%;\n    margin: 0 auto;\n    text-align: center;\n    line-height: 1.2rem;\n  }\n  \n  .events-card-container {\n    margin: -6.5rem 0 6rem 0;\n  }\n  \n  @media screen and (max-width: 560px) {\n    .ProjectPage-title-container h1 {\n      font-size: 30px;\n      font-weight: 400;\n      width: 90%;\n      margin: 0 auto 0.7rem auto;\n      text-align: center;\n    }\n    .ProjectPage-title-container p {\n      font-size: 14px;\n      font-weight: 300;\n      width: 90%;\n      margin: 0 auto;\n      text-align: center;\n      line-height: 1.2rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
