// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loyalty-icon {
    position: fixed; /* Make the icon fixed relative to the viewport */
    top: 520px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    z-index: 10; /* Ensure it appears above other elements */
    cursor: pointer;
    color: yellow;
    font-size: 50px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/loyalty-icon.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,iDAAiD;IAClE,UAAU,EAAE,sCAAsC;IAClD,WAAW,EAAE,wCAAwC;IACrD,WAAW,EAAE,2CAA2C;IACxD,eAAe;IACf,aAAa;IACb,0BAA0B;AAC9B","sourcesContent":[".loyalty-icon {\n    position: fixed; /* Make the icon fixed relative to the viewport */\n    top: 520px; /* Adjust the top position as needed */\n    right: 20px; /* Adjust the right position as needed */\n    z-index: 10; /* Ensure it appears above other elements */\n    cursor: pointer;\n    color: yellow;\n    font-size: 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
