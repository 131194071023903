// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoursePage {
  margin-top: 56px;
}

.CoursePage-title-container {
  background-color: #383838;
  color: #ffffff  !important;
  text-align: center;
  padding: 2rem 0 10rem 0;
}
.CoursePage-title-container h1 {
  font-size: 2.3rem;
  font-weight: 400;
  margin-bottom: 0.7rem;
  color: #ffffff 
}
.CoursePage-title-container h2 {
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.CoursePage-title-container p {
  font-size: 0.8rem;
  font-weight: 300;
  width: 55%;
  margin: 0 auto;
}

.chapter-card-container {
  width: 70%;
  margin: -7rem auto 7rem auto;
  border: 1px solid #d1d9d9;
  border-radius: 8px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CoursePage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB;AACF;AACA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,4BAA4B;EAC5B,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".CoursePage {\n  margin-top: 56px;\n}\n\n.CoursePage-title-container {\n  background-color: #383838;\n  color: #ffffff  !important;\n  text-align: center;\n  padding: 2rem 0 10rem 0;\n}\n.CoursePage-title-container h1 {\n  font-size: 2.3rem;\n  font-weight: 400;\n  margin-bottom: 0.7rem;\n  color: #ffffff \n}\n.CoursePage-title-container h2 {\n  font-weight: 400;\n  margin-bottom: 0.5rem;\n}\n.CoursePage-title-container p {\n  font-size: 0.8rem;\n  font-weight: 300;\n  width: 55%;\n  margin: 0 auto;\n}\n\n.chapter-card-container {\n  width: 70%;\n  margin: -7rem auto 7rem auto;\n  border: 1px solid #d1d9d9;\n  border-radius: 8px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
