// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage {
  background-color: #383838;
  color: #ffffff;
  text-align: center;
  margin-top: 57px;
}

.ErrorPage-heading {
  font-weight: 400;
  font-size: 2.1rem;
  margin-bottom: 0.9rem;
  padding-top: 5rem;
}

.ErrorPage-text {
  width: 55%;
  margin: 0 auto;
  font-size: 0.8rem;
  opacity: 0.8;
  line-height: 1.3rem;
}

.error-img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 25%;
}

@media screen and (max-width: 560px) {
  .ErrorPage-heading {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    font-size: 32px;
  }

  .ErrorPage-text {
    width: 90%;
    font-size: 14px;
  }

  .error-img {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ErrorPage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".ErrorPage {\n  background-color: #383838;\n  color: #ffffff;\n  text-align: center;\n  margin-top: 57px;\n}\n\n.ErrorPage-heading {\n  font-weight: 400;\n  font-size: 2.1rem;\n  margin-bottom: 0.9rem;\n  padding-top: 5rem;\n}\n\n.ErrorPage-text {\n  width: 55%;\n  margin: 0 auto;\n  font-size: 0.8rem;\n  opacity: 0.8;\n  line-height: 1.3rem;\n}\n\n.error-img {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  width: 25%;\n}\n\n@media screen and (max-width: 560px) {\n  .ErrorPage-heading {\n    width: 90%;\n    margin: auto;\n    margin-bottom: 1rem;\n    font-size: 32px;\n  }\n\n  .ErrorPage-text {\n    width: 90%;\n    font-size: 14px;\n  }\n\n  .error-img {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
