// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TermsAndConditionsPage-container {
    padding: 2rem;
    margin-top: 70px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
  }
  
  .terms-title {
    margin-bottom: 2rem;
  }
  
  .terms-img {
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 2rem;
    border-radius: 10px;
    object-fit: cover; 
    transition: transform 0.3s ease-in-out;
  }
  
  .tandc-subtitle {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  
  .tandc-p {
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .terms-title {
      font-size: 1.5rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/t&c.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,sCAAsC;EACxC;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE;MACE,iBAAiB;IACnB;EACF","sourcesContent":["\n.TermsAndConditionsPage-container {\n    padding: 2rem;\n    margin-top: 70px; \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center; \n  }\n  \n  .terms-title {\n    margin-bottom: 2rem;\n  }\n  \n  .terms-img {\n    width: 100%;\n    max-width: 500px;\n    height: auto;\n    padding: 2rem;\n    border-radius: 10px;\n    object-fit: cover; \n    transition: transform 0.3s ease-in-out;\n  }\n  \n  .tandc-subtitle {\n    font-size: 1.5rem;\n    margin-top: 2rem;\n  }\n  \n  .tandc-p {\n    margin-bottom: 1rem;\n  }\n  \n  @media (max-width: 768px) {\n    .terms-title {\n      font-size: 1.5rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
