// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 500;
  border-top: 1px solid #d1d9d9;
  background-color: #ffffff;
}

.SubscriptionCard-left {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.SubscriptionCard-right {
  color: #3eac72;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.unlocked-icons {
  margin-left: 0.4rem;
  width: 12px;
  margin-bottom: -2px;
}

.first-chapter {
  border-top: none;
}

.SubscriptionCard-chapterNo {
  width: 28%;
}
.SubscriptionCard-chapterName {
  width: 72%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SubscriptionCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".SubscriptionCard {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 0.8rem;\n  font-weight: 500;\n  border-top: 1px solid #d1d9d9;\n  background-color: #ffffff;\n}\n\n.SubscriptionCard-left {\n  width: 60%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0.9rem 2.5rem 1rem 2.5rem;\n}\n\n.SubscriptionCard-right {\n  color: #3eac72;\n  width: 30%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0.9rem 2.5rem 1rem 2.5rem;\n}\n\n.unlocked-icons {\n  margin-left: 0.4rem;\n  width: 12px;\n  margin-bottom: -2px;\n}\n\n.first-chapter {\n  border-top: none;\n}\n\n.SubscriptionCard-chapterNo {\n  width: 28%;\n}\n.SubscriptionCard-chapterName {\n  width: 72%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
