// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionCard {
  border-bottom: 1px solid #d1d9d9;
  padding: 0.8rem 2.5rem;
  background-color: #ffffff;
}

.TransactionCard-row-1,
.TransactionCard-row-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.TransactionCard-row-1 p {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.all-chapters-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: grey;
  margin-top: 0;
}

.download-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: #3eac72;
  cursor: pointer;
  margin-top: 0;
}

.first-col {
  width: 35%;
}

.second-col {
  width: 15%;
}

.third-col {
  width: 30%;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/styles/TransactionCard.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".TransactionCard {\n  border-bottom: 1px solid #d1d9d9;\n  padding: 0.8rem 2.5rem;\n  background-color: #ffffff;\n}\n\n.TransactionCard-row-1,\n.TransactionCard-row-2 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.TransactionCard-row-1 p {\n  font-size: 0.8rem;\n  font-weight: 500;\n  margin-bottom: 0.7rem;\n}\n\n.all-chapters-text {\n  font-size: 0.8rem;\n  font-weight: 500;\n  color: grey;\n  margin-top: 0;\n}\n\n.download-text {\n  font-size: 0.8rem;\n  font-weight: 500;\n  color: #3eac72;\n  cursor: pointer;\n  margin-top: 0;\n}\n\n.first-col {\n  width: 35%;\n}\n\n.second-col {\n  width: 15%;\n}\n\n.third-col {\n  width: 30%;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
