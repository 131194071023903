// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.room-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 30px;
}

.fancy-btn {
  font-size: 1.25rem;
  padding: 15px 35px !important;
  border: none;
  border-radius: 50px !important;
  background-color: #ffb53e !important; 
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  
}

.fancy-btn:hover {
  background-color: #ff9900;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  color: white !important;
}

#zmmtg-root {
  display: none; 
}

.image-container {
  margin-bottom: 20px;
}

.image-container img {
  max-width: 100%;

  height: 200px; 
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

 */


 #zmmtg-root {
  display: none; 
}

 main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Zoom.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAsDE;;;CAGD;EACC,aAAa;AACf;;CAEC;EACC,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,qBAAqB;EACrB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* \n.room-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background: linear-gradient(135deg, #f8f9fa, #e9ecef);\n  text-align: center;\n  padding: 20px;\n}\n\nh1 {\n  font-size: 2.5rem;\n  color: #343a40;\n  margin-bottom: 30px;\n}\n\n.fancy-btn {\n  font-size: 1.25rem;\n  padding: 15px 35px !important;\n  border: none;\n  border-radius: 50px !important;\n  background-color: #ffb53e !important; \n  color: white;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n  transition: all 0.3s ease;\n  \n}\n\n.fancy-btn:hover {\n  background-color: #ff9900;\n  transform: translateY(-2px);\n  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);\n  color: white !important;\n}\n\n#zmmtg-root {\n  display: none; \n}\n\n.image-container {\n  margin-bottom: 20px;\n}\n\n.image-container img {\n  max-width: 100%;\n\n  height: 200px; \n  object-fit: cover;\n  border-radius: 10px;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);\n}\n\n */\n\n\n #zmmtg-root {\n  display: none; \n}\n\n main {\n  width: 70%;\n  margin: auto;\n  text-align: center;\n}\n\nmain button {\n  margin-top: 20px;\n  background-color: #2D8CFF;\n  color: #ffffff;\n  text-decoration: none;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  padding-left: 40px;\n  padding-right: 40px;\n  display: inline-block;\n  border-radius: 10px;\n  cursor: pointer;\n  border: none;\n  outline: none;\n}\n\nmain button:hover {\n  background-color: #2681F2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
